var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-5 px-3"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('table',{staticClass:"table table-bordered table-responsive text-start d-table"},[_c('tr',{staticClass:"w-100"},[_c('td',{staticClass:"p-1"},[_c('b-img',{staticClass:"adjustWidth",attrs:{"src":require('@/assets/images/logo/logo_mtca.png'),"alt":"logo"}})],1),_c('td',{staticClass:"p-1"},[_c('h4',{},[_vm._v("CHECKLIST")]),_c('h5',[_vm._v(" "+_vm._s(_vm.$t('demand_detail.committee')))])]),_c('td',{staticClass:"p-1"},[_c('h6',{},[_c('strong',[_vm._v("Reference:")]),_vm._v(" "+_vm._s(_vm.reference))]),_c('h6',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('demand_detail.requestor')))]),_vm._v(" "+_vm._s(_vm.checklist_object.identity))])])])])]),_c('div',{staticClass:"d-flex justify-content-center pt-2 px-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"name":"verif_doc"},on:{"change":function () {
                        _vm.$emit('on_change_checklist_object', _vm.checklist_object);
                    }},model:{value:(_vm.checklist_object.verif_doc),callback:function ($$v) {_vm.$set(_vm.checklist_object, "verif_doc", $$v)},expression:"checklist_object.verif_doc"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('demand_detail.checking')))])])],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h4',[_c('strong',[_vm._v("Observations:")])]),_c('b-form-textarea',{attrs:{"rows":"2"},on:{"change":function () {
                        _vm.$emit('on_change_checklist_object', _vm.checklist_object);
                    }},model:{value:(_vm.checklist_object.verif_observ),callback:function ($$v) {_vm.$set(_vm.checklist_object, "verif_observ", $$v)},expression:"checklist_object.verif_observ"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"name":"traitement_doc"},on:{"change":function () {
                        _vm.$emit('on_change_checklist_object', _vm.checklist_object);
                    }},model:{value:(_vm.checklist_object.traitement_doc),callback:function ($$v) {_vm.$set(_vm.checklist_object, "traitement_doc", $$v)},expression:"checklist_object.traitement_doc"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('demand_detail.treatment')))])])],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h4',[_c('strong',[_vm._v("Observations:")])]),_c('b-form-textarea',{attrs:{"rows":"2"},on:{"change":function () {
                        _vm.$emit('on_change_checklist_object', _vm.checklist_object);
                    }},model:{value:(_vm.checklist_object.traitement_observ),callback:function ($$v) {_vm.$set(_vm.checklist_object, "traitement_observ", $$v)},expression:"checklist_object.traitement_observ"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"name":"validation_doc"},on:{"change":function () {
                        _vm.$emit('on_change_checklist_object', _vm.checklist_object);
                    }},model:{value:(_vm.checklist_object.validation_doc),callback:function ($$v) {_vm.$set(_vm.checklist_object, "validation_doc", $$v)},expression:"checklist_object.validation_doc"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('demand_detail.validation')))])])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h4',[_c('strong',[_vm._v("Observations:")])]),_c('b-form-textarea',{attrs:{"rows":"2"},on:{"change":function () {
                        _vm.$emit('on_change_checklist_object', _vm.checklist_object);
                    }},model:{value:(_vm.checklist_object.validation_observ),callback:function ($$v) {_vm.$set(_vm.checklist_object, "validation_observ", $$v)},expression:"checklist_object.validation_observ"}})],1)],1)],1),_c('b-row',{staticClass:"mt-2 d-flex justify-content-end"},[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"8 "}},[_c('h4',{staticClass:"pb-5 text-center"},[_vm._v(" Signature "),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$t('demand_detail.visa')))]),_c('iframe',{staticStyle:{"width":"100%","height":"24vh"},attrs:{"src":_vm.curr_user_signature_url ? _vm.curr_user_signature_url : _vm.fileContent}},[_vm._v(" "+_vm._s(_vm.$t('demand_detail.oops'))+" ")]),(!_vm.forPrint)?_c('b-form-file',{staticClass:"mt-1",attrs:{"placeholder":"Veuillez choisir votre signature (PDF / JPEG)","drop-placeholder":"Glissez-déposez le fichier ici...","accept":"image/jpeg, image/jpg, application/pdf","state":!_vm.isValidSignatureFile(_vm.checklist_object.signature_file)
                            ? false
                            : null},on:{"change":_vm.handleFileChange}}):_vm._e(),(!_vm.forPrint)?_c('b-form-input',{staticClass:"col-12",attrs:{"placeholder":"Nom et Prénom(s)"},on:{"change":function () {
                        _vm.$emit('on_change_checklist_object', _vm.checklist_object);
                    }},model:{value:(_vm.checklist_object.signature_nom_prenoms),callback:function ($$v) {_vm.$set(_vm.checklist_object, "signature_nom_prenoms", $$v)},expression:"checklist_object.signature_nom_prenoms"}}):_c('span',[_vm._v(" "+_vm._s(_vm.checklist_object.signature_nom_prenoms)+" ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }